import React, { Component } from 'react';
import "../styles/HomeInfo.css";
import { Link } from 'react-router-dom';

class HomeRight extends Component {
  render() {
    return (
        <div className="image-bg image-right">
            <div className="right-bg">
                <div className="right-info">
                    <div className="info-title-ctr">
                        <h1><i>Our Services</i></h1>
                    </div>
                    <div className="info-text-ctr">
                        <h3>- Primary Care</h3>
                        <h3>- Preventive Care</h3>
                        <h3>- Wellness Examination</h3>
                        <h3>- Spirometry Testing</h3>
                        <h3>- Annual Physicals</h3>
                        <h3>- EKG</h3>
                        <h3>- Full Laboratory Testing</h3>
                        <h3>- Adult Immunizations</h3>
                        <h3>- Audiology Testing</h3>
                        <h3>- Pre-operative Clearance</h3>
                    </div>
                    <div className="info-btn-ctr">
                        <Link to="/view-forms">
                            <button className="info-btn">View Available Forms</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default HomeRight