import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './routes/Home'
import Practice from './routes/Practice'
import Forms from './routes/Forms'

class App extends Component {
  state = {
    active: "home",
  }

  handleScroll() {
    const header = document.querySelector(".navbar-ctr");
    const logo = document.querySelector(".logo-ctr");
    const body = document.querySelector("body");
    const bodyTop = body.getBoundingClientRect().top;
    if (header !== null) {
      if (bodyTop > -10) {
        header.classList.remove("scrolled");
        logo.classList.remove("scrolled");
      } else {
        header.classList.add("scrolled");
        logo.classList.add("scrolled");
      }
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/our-practice" element={<Practice />} />
          <Route path="/view-forms" element={<Forms />} />
        </Routes>
      </div>
    )
  }
}

export default App
