import React, { Component } from 'react'
import "../styles/Navbar.css"
import Logo from './Logo'
import MenuButton from './MenuButton'
import Phone from './Phone';
import { Link } from "react-router-dom";


class Navbar extends Component {
  state = {
    active: "home",
    open: false,
  }

  setActive(e) {
    this.setState({
        active: e.target.id,
    })
  }

  componentDidMount() {
    this.setState({
      active: this.props.active
    }, () => {
      const obj = document.getElementById("navbar");
      obj.addEventListener("scroll", e => this.onScroll(e));
    })
  }

  render() {
    const active = this.state.active;
    const open = this.state.open;

    return (
      <div className="navbar-ctr" id="navbar">
        <Phone />
        <Logo />
        <MenuButton open={open} active={active}/>
        <div className="navbar-links">
            <Link to="/">
                <div id="home" className={active === "home" ? "navbar-item active" : "navbar-item"} onClick={e => this.setActive(e)}>
                    Home
                </div>
            </Link>
            <Link to="/our-practice">
                <div id="practice" className={active === "practice" ? "navbar-item active" : "navbar-item"} onClick={e => this.setActive(e)}>
                    Our Practice
                </div>
            </Link>
            <Link to="/view-forms">
                <div id="forms" className={active === "forms" ? "navbar-item active" : "navbar-item"} onClick={e => this.setActive(e)}>
                    View Forms
                </div>
            </Link>
        </div>
      </div>
    )
  }
}

export default Navbar