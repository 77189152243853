import React, { Component } from 'react';
import { Link } from "react-router-dom";

class MenuButton extends Component {
    state = {
        open: false,
    }

    toggleMenu(e) {
        e.preventDefault();

        this.setState({
            open: !this.state.open
        })
    }
  
    render() {
        const open = this.state.open;
        const active = this.props.active;

        return (
            <div className="mobile-menu-ctr">
                <div className="menu-btn-ctr" onClick={e => this.toggleMenu(e)}>
                    {!open ? 
                        <div className="menu-lines-closed">
                            <div className="menu-line-1" />
                            <div className="menu-line-2" />
                            <div className="menu-line-3" />
                        </div> :
                        <div className="menu-lines-open">
                            <div className="menu-line-1" />
                            <div className="menu-line-2" />
                        </div>
                    }
                </div>
                <div className={this.state.open ? "menu-bg-overlay menu-bg-open" : "menu-bg-overlay menu-bg-closed"} />
                <div className={this.state.open ? "mobile-menu menu-open" : "mobile-menu menu-closed"}>
                    <Link to="/">
                        <div id="home" className={active === "home" ? "navbar-item active" : "navbar-item"}>
                            Home
                        </div>
                    </Link>
                    <Link to="/our-practice">
                        <div id="practice" className={active === "practice" ? "navbar-item active" : "navbar-item"}>
                            Our Practice
                        </div>
                    </Link>
                    <Link to="/view-forms">
                        <div id="forms" className={active === "forms" ? "navbar-item active" : "navbar-item"}>
                            View Forms
                        </div>
                    </Link>
                </div>
            </div>
        )
  }
}

export default MenuButton