import React, { Component } from 'react'
import "../styles/Hero.css"

class Hero extends Component {
  getHeroText(active) {
    switch (active) {
      case "home":
        return (<div className="hero-text">
                  <h1>Bobby Chandok MD</h1>
                  <h4>PREMIER PRIMARY CARE SPECIALIST</h4>
                  <a href="tel:+1-631-425-1000">  
                    <button className="hero-btn">Give Us a Call!</button> 
                  </a>
                </div>)
      case "practice":
        return (<div className="hero-text">
                  <h1>Our Practice</h1>
                  <h4>PROFESSIONAL CARE FOR YOU</h4>
                  <a href="tel:+1-631-425-1000">  
                    <button className="hero-btn">Give Us a Call!</button> 
                  </a>
                </div>)
      case "forms":
        return (<div className="hero-text">
                  <h1>View Forms</h1>
                  <h4>FILL OUT BEFORE YOU COME IN</h4>
                  <a href="tel:+1-631-425-1000">  
                    <button className="hero-btn">Give Us a Call!</button> 
                  </a>
                </div>)
      default:
        return (<div className="hero-text">
                  <h1>Bobby Chandok MD</h1>
                  <h4>PREMIER PRIMARY CARE SPECIALIST</h4>
                  <a href="tel:+1-631-425-1000">  
                    <button className="hero-btn">Give Us a Call!</button> 
                  </a>
                </div>)
    }
  }

  render() {
    const active = this.props.active;

    return (
        <div className="hero-ctr">
            <div className="bg-overlay" />
            <div className={`hero-img ${active}-img`} />
            {this.getHeroText(active)}
        </div>
    )
  }
}

export default Hero