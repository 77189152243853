import React, { Component } from 'react'
import "../styles/Sides.css";

class Sides extends Component {
  render() {
    return (
        <div className='sides-ctr'>
            <iframe className="left-ctr" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3018.9997995069307!2d-73.41478202347952!3d40.82796807137646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e8290180f397d5%3A0xc908fd09fe24d4c9!2s33%20Walt%20Whitman%20Rd%20%23100b%2C%20Huntington%20Station%2C%20NY%2011746!5e0!3m2!1sen!2sus!4v1693412817267!5m2!1sen!2sus" width="800" height="800" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <div className='right-ctr'>
                <div className='loc-ctr'>
                    <h2>Location and Hours</h2>
                    <div className="loc-sect">
                    <div className="loc-left">
                        <h4>Address</h4>
                    </div>
                    <div className="loc-right">
                        <h4>33 Walt Whitman Rd Suite 100B</h4>
                        <h4>Huntington Station, NY 11746</h4>
                    </div>
                    </div>
                    <div className="loc-sect">
                    <div className="loc-left hours">
                        <h4>Hours</h4>
                    </div>
                    <div className="loc-right">
                        <h4>Monday: 9:00am - 5:00pm</h4>
                        <h4>Tuesday: 9:00am - 6:30pm</h4>
                        <h4>Wed-Fri: 9:00am - 5:00pm</h4>
                        <h4>Sat-Sun: Closed</h4>
                    </div>
                    </div>
                    <div className="loc-sect">
                    <div className="loc-left">
                        <h4>Contact Us</h4>
                    </div>
                    <div className="loc-right">
                        <h4>Phone: (631) 425-1000</h4>
                        <h4>Fax: (631) 425-1008</h4>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default Sides

