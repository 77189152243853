import React, { Component } from 'react';
import "../styles/HomeInfo.css";
import MalePDF from "../pdfs/male.pdf";
import RegPDF from "../pdfs/reg.pdf";
import WomensPDF from "../pdfs/womens.pdf";
import PhqPDF from "../pdfs/phq.pdf";
import X from "../images/exit.png";

class FormLeft extends Component {
    state = {
        form: "default",
        isOpen: false,
    }

    selectForm(e) {
        this.setState({
            form: e.target.value
        })
    }

    togglePopup(e) {
        e.preventDefault();

        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    getPopupForm() {
        const form = this.state.form;

        if (form === "male") {
            return (<div className="popup-form">
                <div className="male-row-1">
                    Male Wellness Form
                </div>
            </div>)
        }
        if (form === "womens") {
            return (<div className="popup-form">
                <div className="womens-row-1">
                    Womens Wellness Questionnaire
                </div>
            </div>)
        }
        if (form === "reg") {
            return (<div className="popup-form">
                <div className="reg-row-1">
                    Patient Registration Form
                </div>
            </div>)
        }
    }

    getPopup() {
        if (this.state.isOpen) {
            return <div className="popup-bg" onClick={e => this.togglePopup(e)}>
                <div className="popup-ctr" onClick={(e) => e.stopPropagation()}>
                    <div className="popup-exit" onClick={e => this.togglePopup(e)}>
                        <img src={X} alt="Exit" />
                    </div>
                    <div className="popup-title">
                        <h2>Fill Out Form</h2>
                    </div>
                    <div className="popup-select">
                        <select value={this.state.form} name="popup-forms" id="popup-forms" onChange={e => this.selectForm(e)}>
                            <option value="male">Male Wellness Form</option>
                            <option value="reg">Patient Registration Form</option>
                            <option value="womens">Womens Wellness Questionnaire</option>
                        </select>  
                    </div>
                    {this.getPopupForm()}
                </div>
            </div>
        } else {
            return <div></div>
        }
    }

    isDefault() {
        const form = this.state.form;

        if (form === "default") {
            return true
        } else {
            return false
        }
    }

    getForm() {
        const form = this.state.form;

        switch (form) {
            case "male":
                return MalePDF;
            case "reg":
                return RegPDF;
            case "womens":
                return WomensPDF;
            case "phq":
                return PhqPDF;
            default:
                return RegPDF;
        }
    }

    getButton() {
        const form = this.state.form;

        if (form !== "default") {
            return (<div className="info-btn-ctr">
                        <a href={this.getForm()} target="_blank" rel="noreferrer">
                            <button disabled={this.isDefault()} className="info-btn">
                                View Form
                            </button>
                        </a>
                    </div>);
        } else {
            return (<div className="info-btn-ctr">
                        <button disabled={this.isDefault()} className="info-btn">
                            View Form
                        </button>
                    </div>);
        }
    }

    render() {
        return (
            <div className="image-bg form-left">
                <div className="left-bg">
                    <div className="left-info">
                        <div className="info-title-ctr">
                            <h1><i>Available Forms</i></h1>
                        </div>
                        <div className="info-text-ctr">
                            <h3>&emsp;You can print out our forms and fill them out before you come in. Use the drop down menu below to select your desired form, and click "View Form" to view and print.</h3>
                        </div>
                        <div className="info-select-ctr">
                            <select value={this.state.form} name="forms" id="forms" onChange={e => this.selectForm(e)}>
                                <option value="default">Select a form...</option>
                                {/* <option value="male">Male Wellness Form</option> */}
                                <option value="reg">Patient Registration Form</option>
                                <option value="phq">Patient Health Questionnaire</option>
                                {/* <option value="womens">Womens Wellness Questionnaire</option> */}
                            </select> 
                        </div>
                        {this.getButton()}
                        {this.getPopup()}
                    </div>
                </div>
            </div>
        )
    }
}

export default FormLeft