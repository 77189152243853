import React, { Component } from 'react';
import "../styles/HomeInfo.css";
import { Link } from 'react-router-dom';

class HomeLeft extends Component {
  render() {
    return (
        <div className="image-bg image-left">
            <div className="left-bg">
                <div className="left-info">
                    <div className="info-title-ctr">
                        <h1><i>Our Practice</i></h1>
                    </div>
                    <div className="info-text-ctr">
                        <h3>&emsp;When you decide to put your health into my hands, it immediately becomes my number one priority. I value the relationships I form with each patient, and believe that goes a long way in improving their health care outcomes. Give me a call to find out how I can help you today.</h3>
                    </div>
                    <div className="info-btn-ctr">
                        <Link to="/our-practice">
                            <button className="info-btn">More About Us</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default HomeLeft