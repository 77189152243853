import React, { Component } from 'react';
import "../styles/Navbar.css";
import PhoneIcon from "../images/phone.png";

class Phone extends Component {
  render() {
    return (
      <div className="phone-ctr">
        <a href="tel:+1-631-425-1000">  
          <div className="phone-icon">
              <h5><img src={PhoneIcon} alt="Call us:" /> (631) 425-1000</h5>
          </div>
          <div className="mobile-phone-icon">
              <img src={PhoneIcon} alt="Call us:" />
          </div>
        </a>
      </div>
    )
  }
}

export default Phone