import React, { Component } from 'react'
import Hero from '../components/Hero'
import HomeLeft from '../components/HomeLeft';
import HomeRight from '../components/HomeRight';
import Sides from '../components/Sides';
import Footer from '../components/Footer';
import "../styles/Home.css";
import Navbar from '../components/Navbar';

class Home extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0 });
  }

  render() {
    return (
      <div className="home-ctr">
        <Navbar active="home" />
        <Hero active="home" />
        <Sides />
        <HomeLeft />
        <HomeRight />
        <Footer />
      </div>
    )
  }
}

export default Home