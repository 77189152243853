import React, { Component } from 'react';
import "../styles/Logo.css";

class Logo extends Component {
  render() {
    return (
      <div className="logo-ctr">
        <h1>Bobby Chandok MD</h1>
        <h2>PREMIER PRIMARY CARE SPECIALIST</h2>
        <h4>"The professional and personal care you deserve."</h4>
      </div>
    )
  }
}

export default Logo