import React, { Component } from 'react';
import "../styles/Footer.css";
// import FB from "../images/fb icon.png";

class Footer extends Component {
  render() {
    return (
      <div className="foot-ctr">
        <div className="foot-text">
          <h6>Address</h6>
          <h6>33 Walt Whitman Rd Suite 100B</h6>
          <h6>Huntington Station, NY 11746</h6>
        </div>
        <div className="foot-text">
          <h6>Contact Us</h6>
          <h6>Phone: (631) 425-1000</h6>
          <h6>Fax: (631) 425-1008</h6>
        </div>
        {/* <div className="foot-fb">
          <a href="https://www.facebook.com/BobbyChandokMD" rel='noopener noreferrer' target='_blank'>
            <img src={FB} alt="Facebook" />
          </a>
        </div> */}
      </div>
    )
  }
}

export default Footer