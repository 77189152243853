import React, { Component } from 'react';
import Portrait from "../images/portrait.png";
import "../styles/Page.css";

class Page extends Component {
  render() {
    return (
        <div className="page-ctr">
            <div className="page-img-ctr">
                <div className="page-img">
                    <img src={Portrait} alt="Bobby Chandok MD" />
                </div>
                <div className="img-text">
                    <h5>Bobby Chandok MD</h5>
                    <h6>PREMIER PRIMARY CARE SPECIALIST</h6>
                </div>
            </div>
            <div className="page-text">
                <h2><i>Our Practice</i></h2>
                <p>&emsp;Dr. Bobby Chandok majored in biochemistry and graduated from Stony Brook University in 1997. He then went to Ross University Medical School, graduating with a medical degree in 2001. Furthermore, he did his internal medicine residency at Columbia University (Stamford, CT campus). He is board certified in Internal Medicine, a diplomat of American College of Physicians and a fellow of Society of Hospital Medicine. After completing his residency in 2004 he assisted in starting a Hospital Medicine program at Northern Westchester Hospital. He not only worked as a full-time Hospitalist but was also the associate program director. After leaving Northern Westchester Hospital in 2008, he joined Huntington Hospital where he continued to practice hospital medicine and also became the medical director of the case management department. With the knowledge and experience from working in the hospital setting, Dr. Chandok realized that patients benefit strongly from a consistent and stable transition between the primary care office and their preferred hospital, should the need arise. While he prioritizes prevention, Dr. Chandok also works to make sure the transition to hospital care is as easy as possible for every patient.</p>
                <br />
                <p>&emsp;After working in the hospital setting Dr. Chandok understands how hard it is not seeing your own doctor when you are most vulnerable. Moreover, it is difficult to find personable care in a multispecialty or large hospital practice therefore on these premises that he opened his own primary care practice in 2016. Premier Primary Care Specialist mission is to provide personalized high-quality care that focuses on primary prevention on an individualized basis. The best way to live a good and a healthy life is not only preventing disease but with evidence-based medicine, slow down the disease process in order to improve the quality of life.</p>
                <br />
                <p style={{textAlign: "center"}}><i>We are a full-service internal medicine practice that focuses on primary prevention.</i></p>
                <br />
                <p>&emsp;Our friendly staff is focused on providing compassionate, comprehensive health care service in a friendly and relaxed atmosphere. We believe in educating our patients as we work with them to achieve their best physical state possible.</p>
            </div>
        </div>
    )
  }
}

export default Page