import React, { Component } from 'react'
import Hero from '../components/Hero'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Page from '../components/Page'

class Practice extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0 });
  }
  
  render() {
    return (
      <div className="practice-ctr">
        <Navbar active="practice" />
        <Hero active="practice" />
        <Page />
        <Footer />
      </div>
    )
  }
}

export default Practice