import React, { Component } from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import Footer from '../components/Footer'
import FormLeft from '../components/FormLeft';

class Forms extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0 });
  }

  render() {
    return (
      <div className="forms-ctr">
        <Navbar active="forms" />
        <Hero active="forms" />
        <FormLeft />
        <Footer />
      </div>
    )
  }
}

export default Forms